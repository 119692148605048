// noti.js
import { createApp, h } from 'vue';
import NotiTemp from '@/components/other/NotiTemp.vue';

let notiInstance = null;



// 단일 noti
// 사용법 this.$noti("msg") or this.$noti("msg", "red")
const createNotiInstance = (msg,color) => {
  const targetElement = document.body;

  notiInstance = createApp({
    render: () => h(NotiTemp, { msg,color }),
  });

  const notiElement = document.createElement('div');
  notiInstance.mount(notiElement);
  targetElement.appendChild(notiElement);

  setTimeout(() => {
    notiInstance.unmount();
    notiElement.remove();
  }, 1500);
};

const noti = function(msg,color) {
  createNotiInstance(msg,color);
};



// object 입력 noti
// 사용법 this.$noti({msg:"msg", color: "red"})
const createNoti2Instance = (options) => {
  const { msg, color } = options;
  const targetElement = document.body;

  notiInstance = createApp({
    render: () => h(NotiTemp, { msg, color }),
  });

  const notiElement = document.createElement('div');
  notiInstance.mount(notiElement);
  targetElement.appendChild(notiElement);

  setTimeout(() => {
    notiInstance.unmount();
    notiElement.remove();
  }, 1500);
};
const noti2 = function(options) {
  createNoti2Instance(options);
};


export default {
  install(Vue) {
    Vue.config.globalProperties.$noti = noti;
    Vue.config.globalProperties.$noti2 = noti2;
  },
};
