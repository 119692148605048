import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/assessment-info',
    name: 'assessment-info',
    component: () => import(/* webpackChunkName: "home" */ '../views/assessment/Info.vue')
  },
  {
    path: '/assessment',
    name: 'assessment',
    component: () => import(/* webpackChunkName: "home" */ '../views/assessment/Solution.vue')
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: () => import(/* webpackChunkName: "home" */ '../views/leadership/Solution.vue')
  },
  {
    path: '/mental-health',
    name: 'mental-health',
    component: () => import(/* webpackChunkName: "home" */ '../views/mentalHealth/Solution.vue')
  },
  {
    path: '/introduction-education',
    name: 'introduction-education',
    component: () => import(/* webpackChunkName: "education" */ '../views/education/IntroductionEducation.vue')
  },
  {
    path: '/education-schedule',
    name: 'education-schedule',
    component: () => import(/* webpackChunkName: "education" */ '../views/education/EducationSchedule.vue')
  },
  {
    path: '/apply-education/:code',
    name: 'apply-education',
    component: () => import(/* webpackChunkName: "education" */ '../views/education/ApplyEducation.vue')
  },
  {
    path: '/apply-education-completed/:code',
    name: 'apply-education-completed',
    component: () => import(/* webpackChunkName: "education" */ '../views/education/ApplyEducationCompleted.vue')
  },

  // cs
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Notice.vue')
  },
  {
    path: '/notice/:code',
    name: 'notice-detail',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/NoticeDetail.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Faq.vue')
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Inquiry.vue')
  },
  {
    path: '/hive',
    name: 'hive',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Hive.vue')
  },
  {
    path: '/hive/:code',
    name: 'hive-detail',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/HiveDetail.vue')
  },


  // sign
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Login.vue')
  },
  
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SignUp.vue')
  },
  
  {
    path: '/find-info',
    name: 'find-info',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/FindInfo.vue')
  },
  {
    path: '/chg-pw',
    name: 'chg-pw',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ChgPw.vue')
  },
  {
    path: '/link/:code',
    name: 'link',
    component: () => import(/* webpackChunkName: "link" */ '../views/sign/LinkConfirm.vue')
  },


  // 마이페이지
  {
    path: '/mypage',
    redirect: '/mypage/my-info-login'
  },
  {
    path: '/mypage/my-info/login',
    name: 'mypage-my-info-login',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyInfoLogin.vue')
  },
  {
    path: '/leave',
    name: 'mypage-leave',
    component: () => import(/* webpackChunkName: "leave" */ '../views/mypage/Leave.vue')
  },
  {
    path: '/mypage/my-info',
    name: 'mypage-my-info',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyInfo.vue')
  },
  {
    path: '/mypage/my-info/additional-info',
    name: 'mypage-additional-info',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/AdditionalInfo.vue')
  },
  {
    path: '/mypage/my-inquiry',
    name: 'mypage-my-inquiry',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyInquiry.vue')
  },
  {
    path: '/mypage/my-inquiry/:code',
    name: 'mypage-my-inquiry-detail',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyInquiryDetail.vue')
  },
  {
    path: '/mypage/my-inquiry/form',
    name: 'mypage-my-inquiry-form',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyInquiryForm.vue')
  },
  {
    path: '/mypage/my-cash',
    name: 'mypage-my-cash',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyCash.vue')
  },



  // 구매/실시
  {
    path: '/order/:idx',
    name: 'order-detail',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/OrderDetail.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/Purchase.vue')
  },
  {
    path: '/conduct-diagnosis',
    name: 'conduct-diagnosis',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/ConductDiagnosis.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/ProjectAll.vue')
  },
  {
    path: '/project-done',
    name: 'project-done',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/ProjectDone.vue')
  },
  {
    path: '/project/:code',
    name: 'project-detail',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/ProjectDetail.vue')
  },
  {
    path: '/project/edit/:code',
    name: 'project-edit',
    component: () => import(/* webpackChunkName: "payment" */ '../views/project/ProjectEdit.vue')
  },
  {
    path: '/project-create/link',
    name: 'project-create-link',
    component: () => import(/* webpackChunkName: "project-create-link" */ '../views/project/ProjectCreateLink.vue')
  },
  {
    path: '/project-create/code',
    name: 'project-create-code',
    component: () => import(/* webpackChunkName: "project-create-code" */ '../views/project/ProjectCreateCode.vue')
  },


  // company
  {
    path: '/vision-house',
    name: 'vision-house',
    component: () => import(/* webpackChunkName: "company" */ '../views/company/VisionHouse.vue')
  },
  {
    path: '/client',
    name: 'client',
    component: () => import(/* webpackChunkName: "company" */ '../views/company/Client.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "company" */ '../views/company/Team.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "company" */ '../views/company/Partner.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "company" */ '../views/company/Map.vue')
  },


  // web link
  {
    path: '/share/:code',
    name: 'share-password',
    component: () => import(/* webpackChunkName: "pw" */ '../views/webLink/Password.vue')
  },
  {
    path: '/share/detail/:code',
    name: 'share-project-detail',
    component: () => import(/* webpackChunkName: "pw" */ '../views/webLink/ProjectDetail.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (to.hash) {
    //   return {
    //     selector: to.hash,
    //     behavior: 'smooth' // 부드러운 스크롤 효과 적용
    //   };
    // } else {
    //   return { x: 0, y: 0 };
    // }
    if( !to.hash ){
      document.querySelector(".app_wrap").scrollTop = 0;
      return { top: 0 };
    }
  }
});


// router.beforeEach((to, from, next) => {
//   if (from.name === 'home' && to.name != from.name) {
//     // 여기에 브라우저 뒤로가기 버튼이 눌렸을 때의 동작을 추가
//     // 예: this.step 감소 및 라우트 이동 방지
//     // next(false); // 이동 방지
//     console.log("aa");
//     // return;
//   }
//   // next();
// });


export default router
