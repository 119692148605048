// common.js


const StopBackgroundScroll = function(bool) {
    // if( bool ){
    //     document.body.classList.add("overflow-hidden")
    // }else{
    //     document.body.classList.remove("overflow-hidden")
    // }
};

const Copy = function(text) {
  var textArea = document.createElement("textarea");

  textArea.value = text;

  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.background = "transparent";
  
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");

  document.body.removeChild(textArea);

  this.$alert("복사되었습니다.");
};

export default {
  install(Vue) {
    Vue.config.globalProperties.$StopBackgroundScroll = StopBackgroundScroll;
    Vue.config.globalProperties.$copy = Copy;
  },
};
