// axios
import axios from 'axios'
import store from './store';

const baseURL = process.env.VUE_APP_API_URL;

export default axios.create({
  baseURL: baseURL,
  // You can add your headers here
  headers: {
    Authorization: store.state.token,
	}
})
