import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import notiPlugin from "./assets/js/noti.js";
import alertPlugin from "./assets/js/alert.js";
import commonJs from "./assets/js/common.js";

import axios from "./axios.js"
import VueCookies from 'vue-cookies'

// import naver from 'vue-naver-maps';

const app = createApp(App);

app.config.globalProperties.axios = axios;

// app.use(naver, {
//     clientID: 'or1wv14o37',
//     useGovAPI: false, //공공 클라우드 API 사용 (선택)
//     subModules:'' // 서브모듈 (선택)
//   });

app.use(VueCookies, { expires: '1m'})

app.use(store).use(router);
app.use(notiPlugin);
app.use(alertPlugin);
app.use(commonJs);

app.mount('#app');