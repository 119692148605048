<template>
    <div class="alert-container position-fixed top-0 start-0">
        <div class="dimm w-100 h-100 bg-dark bg-opacity-50 position-absolute top-0 start-0"></div>
        <div class="content position-absolute top-50 start-50 bg-body bg-opacity-85 backdrop-blur-10 rounded-3 text-center pt-5 pb-4 px-4">
            <div class="inner text-start mb-5 px-3">
                <div v-html="msg"></div>
            </div>
            <div class="border-gray-relative-500 flex-end-center pb-3 px-3 gap-2">
                <button class="py-3 btn btn-transparent border px-4" @click="ok()">{{ok_btn_txt}}</button>
                <button class="py-3 btn bg-gray-300 px-4" v-if="is_confirm" @click="cancel()">{{cancel_btn_txt}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        idx: {
            type: Number
        },
        msg: {
            type: String
        },
        is_confirm: {
            type: Boolean,
            default: false
        },
        ok_btn_txt: {
            type: String,
            default: '확인'
        },
        cancel_btn_txt: {
            type: String,
            default: '취소'
        },
        auto_hide: {
            type: Boolean,
            default: false
        },
        ok: {
          type: Function,
        },
        cancel: {
          type: Function,
        },
    },
    methods:{
    },
}
</script>

<style lang="scss" scoped>
    .alert-container{
        width: 100vw !important;
        max-width: 100vw !important;
        height: 100vh;
        z-index: 9999;

        .dimm{
            animation: fadeIn 0.2s;
        }
        .content{
            width: 90%;
            max-width: 400px;
            min-width: 260px;
            transform-origin: 0 0;
            animation: zoomin 0.2s;
            animation-fill-mode: forwards;
            
            .border-top{
                border-width: 0.5px !important;
            }
        }
    }
    @keyframes fadeIn{
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes zoomin{
        from { transform: scale(0.8) translate(-50%, -50%); }
        to { transform: scale(1) translate(-50%, -50%); }
    }
</style>