// alert.js
import {
    createApp,
    h
} from 'vue';
import AlertTemp from '@/components/other/AlertTemp.vue';

let alertInstance = null;
const alert_list = [];

function createAlertInstance(msg, is_confirm, okFn, cancelFn) {
    const alertElement = document.createElement('div');
    const targetElement = document.body;

    const idx = alert_list.length > 0 ? alert_list.length : 0;

    const AlertInstance = createApp({
        render: () => h(AlertTemp, {
			idx: idx,
            msg: msg,
            is_confirm: is_confirm,
			ok: function(){
                okFn && okFn();
                alert_off(idx);
			},
			cancel: function(){
                cancelFn && cancelFn();
                alert_off(idx);
			}
        }),
    });

    AlertInstance.mount(alertElement);
    targetElement.appendChild(alertElement);

    alert_list.push({
        idx: idx,
        item: AlertInstance,
		div: alertElement
    });
}

const alert = function(msg) {
	return new Promise((resolve, reject) => {
		createAlertInstance(msg, false, resolve);
	});
};

const confirm = function(msg, okFn, cancelFn) {
	return new Promise((resolve, reject) => {
		createAlertInstance(msg, true, () => resolve({is_ok: true}), () => resolve({is_cancel: true}));
	});
};



function createPpInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, okFn, cancelFn) {
    const alertElement = document.createElement('div');
    const targetElement = document.body;

    const idx = alert_list.length > 0 ? alert_list.length : 0;

    const AlertInstance = createApp({
        render: () => h(AlertTemp, {
			idx: idx,
            msg: msg,
            is_confirm: is_confirm,
			ok_btn_txt: ok_btn_txt,
			cancel_btn_txt: cancel_btn_txt,
			ok: function(){
                okFn && okFn();
                alert_off(idx);
			},
			cancel: function(){
                cancelFn && cancelFn();
                alert_off(idx);
			}
        }),
    });

    AlertInstance.mount();
    targetElement.appendChild(alertElement);

    alert_list.push({
        idx: idx,
        item: AlertInstance,
		div: alertElement
    });
}


const pp = function(obj) {
	return new Promise((resolve, reject) => {
		const msg = obj.msg;
		const is_confirm = obj.is_confirm;
		const ok_btn_txt = obj.ok_btn_txt;
		const cancel_btn_txt = obj.cancel_btn_txt;

		createPpInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, () => resolve({is_ok: true}), () => resolve({is_cancel: true}));
	});
};




function alert_off(idx, div) {
    const idxToRemove = alert_list.findIndex(item => item.idx === idx);
    const alertInstanceToRemove = alert_list[idxToRemove].item;
    const alertDivToRemove = alert_list[idxToRemove].div;

    alert_list.splice(idxToRemove, 1);
    alertInstanceToRemove.unmount();
	alertDivToRemove.remove()
}

export default {
	install(Vue) {
	  Vue.config.globalProperties.$alert = alert;
	  Vue.config.globalProperties.$confirm = confirm;
	  Vue.config.globalProperties.$pp = pp;
	},
  };




// 예시

// ==========Alert
// this.$alert("<h5 class='pb-3'> Title </h5> some msg").then( ()=>{
// } )


// ==========Confirm
// this.$confirm("some msg").then( (result)=>{
//     if(result.is_ok){}
//     else if(result.is_cancel){}
// })


// ==========Custom
// this.$pp({
//     msg: 'some msg', 
//     is_confirm: true, 
//     ok_btn_txt: 'yes text', 
//     cancel_btn_txt: 'cancel text'
// }).then( (result)=>{
//     if(result.is_ok){}
//     else if(result.is_cancel){}
// })