<!-- NotiTemp.vue -->
<template>
  <div class="noti" :class="color">
	{{ msg }}
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "green",
    },
  },
};
</script>

<style scoped>
.noti {
  font-family: 'Red Hat Display','Urbanist','Noto Sans KR', Helvetica, Arial, sans-serif;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 14px;
  width: 80%;
  max-width: 350px;
  border-radius: 5px;
}

.noti.green {
  background-color: #e2f2e7;
  color: #25a249;
  border-left: 5px solid #25a249;
}

.noti.red {
  background-color: rgb(250, 202, 208);
  color: rgb(195, 34, 55);
  border-left: 5px solid rgb(240, 91, 110);
}
</style>
